@font-face {
  font-family: 'Tasty Birds Fried';
  font-style: normal;
  font-weight: normal;
  src: local('Tasty Birds Fried'), url('/public/fonts/Tasty_Birds_Fried.otf') format('otf');
}

.App {
  text-align: center;
}
