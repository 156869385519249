.hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("/public/hero.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top; 
  transform: translateX(-50%);
}

.hero {
  position: relative;
  overflow: visible;
  display: block;
  padding-bottom: 3.5rem;
}

.hero > h1 {
  padding: 10rem 0 4rem 0;
  margin: 0;
  text-transform: uppercase;
  font-size: 5em;
  font-family: 'Tasty Birds Fried';
}

.hero > p {
  font-size: 1.2em;
  width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}

.hero > a {
  height: 100%;
}
