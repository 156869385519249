.card > .link-button {
    width: 70%;
    margin-left: auto;
    margin-right: auto; 
}

.card {
    text-align: center;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
}

.card h3 {
    font-family: 'Tasty Birds Fried';
    font-size: 2rem;
}

.card ul {
    list-style-type: none;
    padding: 0;
    padding-bottom: 2rem;
    line-height: 2rem;
}

.card img {
    border-radius: 50%;
    width: max(30vw, 20em);
    clip-path: circle();
}

.card p {
    padding: 0;
    margin: 0;
}

.card-upper {
    flex-grow: 1;
}
