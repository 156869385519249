.premium-links {
  background-color: hsla(26.08695652,85.18518519%,94.70588235%,1);
  margin-top: 0;
  padding-bottom: 3rem;
}

.premium-links > h2 {
  font-family: 'Tasty Birds Fried';
  font-size: 4rem;
  margin-top: 0;
  padding-top: 3rem;
}