header > h2 {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: absolute;
    width: 100%;
    text-transform: uppercase;
    font-family: 'Tasty Birds Fried';
    font-size: 2.5rem;
}

header > h2 > a {
    color: black;
    text-decoration: none;
}
